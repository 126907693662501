<template>
  <div>
    <navbar></navbar>
    <div class="main-content">
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
import Navbar from "@/components/Navbar/Navbar";

export default {
  components: {
    Navbar,
  },
  props: {
    backgroundColor: {
      type: String,
      default: "black",
    },
  },
  data() {
    return {};
  },

  methods: {
    setBackgroundColor() {
      document.body.classList.add("bg-default");
    },
    removeBackgroundColor() {
      document.body.classList.remove("bg-default");
    },
    updateBackground() {
      if (!this.$route.meta.noBodyBackground) {
        this.setBackgroundColor();
      } else {
        this.removeBackgroundColor();
      }
    },
  },
  beforeUnmount() {
    this.removeBackgroundColor();
  },

  watch: {
    $route: {
      immediate: true,
      handler: function () {
        this.updateBackground();
      },
    },
  },
};
</script>
<style lang="scss"></style>
