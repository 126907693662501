<template>
  <div>
    <!-- Enter Map Here-->
    <div id="map"></div>
    <!--##################-->
  </div>
</template>
<script>
import mapboxgl from "mapbox-gl";

export default {
  components: {},
  data() {
    return {
      map: null,
      mapCenter: [13.0309757, 47.809308],
    };
  },
  computed: {
    coordinates() {
      var route = this.$store.state.route;

      if (route == null || route.length == 0) {
        return [];
      }

      var array = [];

      for (var obj of route) {
        var geoJson = JSON.parse(obj.st_asgeojson);
        array.push(geoJson.coordinates);
      }

      return array;
    },
    listOfAllPoints() {
      var route = this.$store.state.route;
      var array = [];

      for (var obj of route) {
        var obj_new = {
          id: obj.id,
          coordinates: [obj.longitude, obj.latitude],
          riskscoremax: obj.riskscoremax,
        };
        array.push(obj_new);
      }
      return array;
    },
  },

  methods: {
    drawMap() {
      if (this.map != null) {
        //remove old mapb
        this.map.remove();
      }

      //set set center of the map to the first coordinate of the route
      if (this.listOfAllPoints.length !== 0) {
        this.mapCenter = this.listOfAllPoints[0].coordinates;
      }

      mapboxgl.accessToken =
        "pk.eyJ1IjoibXNlZWJlciIsImEiOiJja2dkbmYweTUwMWhnMnBwZnRydDR5azU4In0.TmgaSKCwXkgJU9xaT62iew";
      this.map = new mapboxgl.Map({
        container: "map",
        style: "mapbox://styles/mapbox/light-v10",
        center: this.mapCenter,
        zoom: 15,
        attributionControl: false,
      });

      this.map.on("load", () => {
        this.map.addSource("route", {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: [
              {
                type: "Feature",
                properties: {},
                geometry: {
                  type: "MultiLineString",
                  coordinates: this.coordinates,
                },
              },
            ],
          },
        });
        this.map.addLayer({
          id: "route",
          type: "line",
          source: "route",
          layout: {
            "line-join": "round",
            "line-cap": "round",
          },
          paint: {
            "line-color": "#329999",
            "line-width": 7,
          },
        });
        const allPoints = this.listOfAllPoints.map((point) => ({
          type: "Feature",
          properties: {
            id: point.id,
            riskscoremax: point.riskscoremax,
          },
          geometry: {
            type: "Point",
            coordinates: point.coordinates,
          },
        }));

        this.map.addLayer({
          id: "points",
          type: "circle",
          source: {
            type: "geojson",
            data: {
              type: "FeatureCollection",
              features: allPoints,
            },
          },
          paint: {
            "circle-radius": 5,
            //"circle-color": "#FFC300",
            // Color circles by riskscoremax, using a `match` expression.
            "circle-color": [
              "match",
              ["get", "riskscoremax"],
              0,
              "#2dc937",
              1,
              "#e7b416",
              2,
              "#db7b2b",
              3,
              "#cc3232",
              /* other */ "#ccc",
            ],
          },
        });

        // Create a popup, but don't add it to the map yet.
        const popup = new mapboxgl.Popup({
          closeButton: false,
          closeOnClick: false,
        });

        this.map.on("mouseenter", "points", (e) => {
          // Change the cursor style as a UI indicator.
          this.map.getCanvas().style.cursor = "pointer";

          // Copy coordinates array.
          const coordinates = e.features[0].geometry.coordinates.slice();
          const id = e.features[0].properties.id;

          // Ensure that if the map is zoomed out such that multiple
          // copies of the feature are visible, the popup appears
          // over the copy being pointed to.
          while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
          }

          // Populate the popup and set its coordinates
          // based on the feature found.
          popup.setLngLat(coordinates).setHTML(id).addTo(this.map);
        });

        this.map.on("mouseleave", "places", () => {
          this.map.getCanvas().style.cursor = "";
          popup.remove();
        });
      });
    },
  },
  mounted() {
    this.drawMap();
  },
  watch: {
    coordinates() {
      this.drawMap();
    },
  },
};
</script>
<style scoped>
#map {
  height: 400px;
}
</style>
