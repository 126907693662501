import Chart from "chart.js";
import "./roundedCornersExtension";

export const barChart = {
  createChart(chartId) {
    const ctx = document.getElementById(chartId).getContext("2d");

    let chart = new Chart(ctx, {
      type: "bar",
      data: {
        labels: ["1", "2", "3", "4"],
        datasets: [
          {
            label: "Cases",
            tension: 0.4,
            borderWidth: 0,
            pointRadius: 0,
            backgroundColor: "#47b881",
            data: [25, 7, 30, 16],
            maxBarThickness: 100,
          },
          {
            label: "Cases",
            tension: 0.4,
            borderWidth: 0,
            pointRadius: 0,
            backgroundColor: "#00783e",
            data: [5, 14, 25, 22],
            maxBarThickness: 100,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        tooltips: {
          enabled: true,
          mode: "index",
          intersect: false,
        },
        scales: {
          yAxes: [
            {
              gridLines: {
                borderDash: [2],
                borderDashOffset: [2],
                zeroLineColor: "#dee2e6",
                drawBorder: false,
                drawTicks: false,
                lineWidth: 1,
                zeroLineWidth: 1,
                zeroLineBorderDash: [2],
                zeroLineBorderDashOffset: [2],
              },
              ticks: {
                beginAtZero: true,
                padding: 10,
                fontSize: 13,
                fontColor: "#8898aa",
                fontFamily: "Open Sans",
                callback: function (value) {
                  if (!(value % 10)) {
                    return value;
                  }
                },
              },
            },
          ],
          xAxes: [
            {
              gridLines: {
                drawBorder: false,
                drawOnChartArea: false,
                drawTicks: false,
              },
              ticks: {
                padding: 20,
                fontSize: 13,
                fontColor: "#8898aa",
                fontFamily: "Open Sans",
              },
            },
          ],
        },
      },
    });

    return chart;
  },
};
