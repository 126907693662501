<template>
  <div>
    <div ref="plot1" id="plot1" class="plot"></div>
  </div>
</template>
<script>
import Plotly from "plotly.js-dist-min";

var layout = {
  margin: {
    l: 50,
    r: 20,
    b: 50,
    t: 0,
    //pad: 1,
  },
  xaxis: {
    title: {
      text: "Time",
      font: {
        size: 16,
        color: "#888",
      },
    },
  },
  yaxis: {
    title: {
      text: "Speed in km/h",
      font: {
        size: 16,
        color: "#888",
      },
    },
  },
  //paper_bgcolor: "#7f7f7f",
  //plot_bgcolor: "#c7c7c7",
};
const config = {
  displayModeBar: false, // this is the line that hides the bar.
};

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    Plotly,
  },
  data() {
    return {};
  },
  methods: {
    plotScatter() {
      Plotly.newPlot(this.$refs.plot1, this.plotlyData, layout, config);
    },
  },
  mounted() {},
  computed: {
    getRoute() {
      return this.$store.state.route;
    },
    getX() {
      var x = [];
      for (const element of this.getRoute) {
        x.push(element.datetime);
      }
      return x;
    },
    getY() {
      var y = [];
      for (const element of this.getRoute) {
        y.push(element.speed);
      }
      return y;
    },
    plotlyData() {
      var data = [
        {
          x: this.getX,
          y: this.getY,
          type: "scatter",
          mode: "lines",
          name: "speed",
          line: { shape: "spline", color: "#58D8DC", width: 3 },
        },
      ];
      return data;
    },
  },
  watch: {
    getRoute() {
      this.plotScatter();
    },
  },
};
</script>
<style scoped>
.plot {
  width: 100%;
}
.modebar-container {
  display: none !important;
}
</style>
