
import axios from 'axios';

function getURL() {
  // Set the API_URL to the value defined in the docker-compose file. This enables a dev/production environment
  if (
    typeof process.env.VUE_APP_API_URL == "undefined" ||
    process.env.VUE_APP_API_URL == ""
  ) {
    return "http://0.0.0.0:4000";
  } else {
    return process.env.VUE_APP_API_URL;
  }
}


class AuthService {

  login(user) {
    const API_URL = getURL();

    return axios
      .post(API_URL + '/login', {
        username: user.username,
        password: user.password
      })
      .then(response => {
        if (response.data.accessToken) {
          localStorage.setItem('user', JSON.stringify(response.data));
        }

        return response.data;
      });
  }

  logout() {
    localStorage.removeItem('user');
  }


}

export default new AuthService();