<template>
  <div>
    <div class="popup-placeholder">
      <div ref="htmlPopup">
        <h3>{{ properties.link_id }}</h3>
        <ul>
          <li><b>Name:</b> {{ properties.name1 }}</li>
          <li><b>speed_tow_car:</b> {{ properties.speed_tow_car }}</li>
          <li><b>speed_bkw_car:</b> {{ properties.speed_bkw_car }}</li>
          <li><b>maxspeed_tow_car:</b> {{ properties.maxspeed_tow_car }}</li>
          <li><b>maxspeed_bkw_car:</b> {{ properties.maxspeed_bkw_car }}</li>
          <li><b>lanes_tow:</b> {{ properties.lanes_tow }}</li>
          <li><b>lanes_bkw:</b> {{ properties.lanes_bkw }}</li>
          <li><b>width_link:</b> {{ properties.width_link }}</li>
          <li><b>gradient:</b> {{ properties.gradient }}</li>
          <li><b>street_length:</b> {{ properties.street_length }}</li>
          <li><b>risk_score_sum:</b> {{ properties.risk_score_sum }}</li>
          <li><b>density:</b> {{ properties.density }}</li>
          <li><b>mos:</b> {{ properties.mos }}</li>
          <li><b>acc:</b> {{ properties.acc }}</li>
        </ul>
        <button class="btn btn-primary" @click="copyData">Copy Data</button>
      </div>
    </div>
    <!-- Enter Map Here-->
    <div id="map"></div>
    <!--##################-->
  </div>
</template>
<script>
import jsonData from "../assets/sbg_gip_combined.json";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
var map = null;
export default {
  components: {},
  data() {
    return {
      map: null,
      properties: {},
    };
  },

  methods: {
    copyData() {
      console.log("copy data");
      this.$store.commit("replaceLinkProperties", this.properties);
    },
    drawMap() {
      if (map != null) {
        //remove old mapb
        map.remove();
      }
      mapboxgl.accessToken =
        "pk.eyJ1IjoibXNlZWJlciIsImEiOiJja2dkbmYweTUwMWhnMnBwZnRydDR5azU4In0.TmgaSKCwXkgJU9xaT62iew";
      map = new mapboxgl.Map({
        container: "map",
        style: "mapbox://styles/mapbox/light-v10",
        center: [13.0396889998107, 47.8018793724683],
        zoom: 12,
        attributionControl: false,
      });

      map.on("load", () => {
        map.addSource("route", {
          type: "geojson",
          data: jsonData,
        });

        map.addLayer({
          id: "route",
          type: "line",
          source: "route",
          layout: {
            "line-join": "round",
            "line-cap": "round",
          },
          paint: {
            "line-color": "#329999",
            "line-width": 4,
          },
        });
        // invisible layer that is bigger, so it's easier to click
        map.addLayer({
          id: "route-click",
          type: "line",
          source: "route",
          layout: {
            "line-join": "round",
            "line-cap": "round",
          },
          paint: {
            "line-color": "#329999",
            "line-width": 15,
            "line-opacity": 0.001,
          },
        });
        map.on("click", "route-click", (e) => {
          // copy properties
          this.properties = e.features[0].properties;

          // Copy coordinates array.
          const coordinates = [e.lngLat.lng, e.lngLat.lat];
          //const coordinates = e.features[0].geometry.coordinates.slice();
          //const description = e.features[0].properties.description;

          // Ensure that if the map is zoomed out such that multiple
          // copies of the feature are visible, the popup appears
          // over the copy being pointed to.
          while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
          }

          new mapboxgl.Popup()
            .setLngLat(coordinates)
            .setDOMContent(this.$refs.htmlPopup)
            .addTo(map);
        });

        // Change the cursor to a pointer when the mouse is over the places layer.
        map.on("mouseenter", "route-click", () => {
          map.getCanvas().style.cursor = "pointer";
        });

        // Change it back to a pointer when it leaves.
        map.on("mouseleave", "route-click", () => {
          map.getCanvas().style.cursor = "";
        });
      });
    },
  },
  mounted() {
    this.drawMap();
  },
};
</script>
<style scoped>
#map {
  height: 700px;
}

.mapboxgl-popup {
  max-width: 400px;
  font: 12px/20px "Helvetica Neue", Arial, Helvetica, sans-serif;
  box-shadow: 5px 5px 10px 5px rgba(68, 68, 68, 0.58) !important;
}

.popup-placeholder {
  display: none;
}
</style>
