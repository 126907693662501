import { createRouter, createWebHistory } from "vue-router";

import DashboardLayout from "@/views/Layout/DashboardLayout";
import AuthLayout from "@/views/Layout/AuthLayout";

// Dashboard pages
import Monitoring from "../views/Monitoring.vue";
import HotSpots from "../views/HotSpots.vue";
import Scenarios from "../views/Scenarios.vue";
import Routing from "../views/Routing.vue";

// Login
import Login from "../views/Login.vue";

const routes = [
  {
    path: "/",
    redirect: "/monitoring",
    component: DashboardLayout,
    name: "Dashboard",
    children: [
      {
        path: "/monitoring",
        name: "Monitoring",
        components: { default: Monitoring },
      },
      {
        path: "/hotspots",
        name: "HotSpots",
        components: { default: HotSpots },
      },
      {
        path: "/routing",
        name: "Routing",
        components: { default: Routing },
      },
      {
        path: "/scenarios",
        name: "Scenarios",
        components: { default: Scenarios },
      },
    ],
  },
  {
    path: "/",
    redirect: "/",
    component: AuthLayout,
    children: [
      {
        path: "/login",
        name: "Login",
        components: { default: Login },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  linkActiveClass: "active",
  routes,
});


// check Authorized status everytime a navigating action is trigger
router.beforeEach((to, from, next) => {
  const publicPages = ['/login'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');

  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next('/login');
  } else {
    next();
  }
});

export default router;
