<template>
  <div>
    <card>
      <template v-slot:header>
        <h3 class="mb-0">Data Points</h3>
        <p class="text-sm mb-0">List of all the data points.</p>
      </template>
      <div>
        <div
          class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
        >
          <el-select
            class="select-primary pagination-select"
            v-model="pagination.perPage"
            placeholder="Per page"
          >
            <el-option
              class="select-primary"
              v-for="item in pagination.perPageOptions"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>

          <div>
            <el-input
              type="search"
              class="mb-3"
              clearable
              prefix-icon="el-icon-search"
              style="width: 200px"
              placeholder="Search by id"
              v-model="searchQuery"
              aria-controls="datatables"
            >
            </el-input>
          </div>
        </div>
        <el-table
          :data="queriedData"
          row-key="id"
          header-row-class-name="thead-light"
        >
          <el-table-column
            v-for="column in tableColumns"
            :key="column.label"
            v-bind="column"
          >
          </el-table-column>
          <el-table-column
            min-width="130px"
            align="right"
            label="Warning"
            sortable
          >
            <template v-slot:default="props">
              <div class="d-flex align-items-center">
                <badge rounded v-if="props.row.riskbool > 0" type="warning">
                  Warning
                </badge>
                <badge rounded v-if="props.row.riskbool < 1" type="success">
                  No Warning
                </badge>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            min-width="130px"
            align="right"
            label="Riskscore"
            sortable
          >
            <template v-slot:default="props">
              <div>
                {{ props.row.riskscoremax }}
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <template v-slot:footer>
        <div
          class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
        >
          <div class="">
            <p class="card-category">
              Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
            </p>
          </div>
          <base-pagination
            class="pagination-no-border"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="total"
          >
          </base-pagination>
        </div>
      </template>
    </card>
  </div>
</template>
<script>
import {
  ElTable,
  ElTableColumn,
  ElSelect,
  ElOption,
  ElInput,
} from "element-plus";
import BasePagination from "@/components/BasePagination";
import Badge from "@/components/Badge.vue";

export default {
  components: {
    BasePagination,
    Badge,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElInput.name]: ElInput,
    [ElTableColumn.name]: ElTableColumn,
  },
  computed: {
    pagedData() {
      return this.tableData.slice(this.from, this.to);
    },
    tableData() {
      var route = this.$store.state.route;
      return route;
    },
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedData}
     */
    queriedData() {
      if (!this.searchQuery) {
        return this.pagedData;
      }
      let result = this.tableData.filter((row) => {
        let isIncluded = false;
        for (let key of this.propsToSearch) {
          let rowValue = row[key].toString();
          if (rowValue.includes && rowValue.includes(this.searchQuery)) {
            isIncluded = true;
          }
        }
        return isIncluded;
      });

      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
  },
  data() {
    return {
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      searchQuery: "",
      propsToSearch: ["id"],
      tableColumns: [
        {
          prop: "id",
          label: "ID",
          minWidth: 90,
          sortable: true,
        },
        {
          prop: "datetime",
          label: "Timestamp",
          minWidth: 200,
          sortable: true,
        },

        {
          prop: "speed",
          label: "Speed",
          minWidth: 120,
          sortable: true,
        },
      ],
      fuseSearch: null,
      searchedData: [],
    };
  },
  methods: {},
};
</script>
<style>
.no-border-card .card-footer {
  border-top: 0;
}
</style>
