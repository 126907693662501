<template>
  <nav class="navbar navbar-expand">
    <div class="container">
      <router-link class="navbar-brand" to="/">
        <img src="img/sinus-logo.png" width="120" />
      </router-link>
      <div>
        <ul class="navbar-nav">
          <li class="nav-item">
            <router-link to="/monitoring" class="nav-link">
              <span class="nav-link-inner--text">Dashboard</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/login" class="nav-link">
              <span class="nav-link-inner--text">Login</span>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
export default {
  name: "navbar",
  components: {},
};
</script>
<style scoped>
nav {
  background: none;
  display: flex !important;
  height: 70px;
  margin-bottom: -70px;
  z-index: 100;
}
</style>
