<template>
  <div class="wrapper">
    <!-- <notifications></notifications> -->
    <side-bar>
      <template v-slot:links>
        <sidebar-item
          :link="{
            name: 'Monitoring',
            icon: 'fas fa-chart-bar text-info',
            path: '/monitoring',
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'Hot-Spots',
            icon: 'fas fa-map-marked-alt text-primary',
            path: '/hotspots',
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'Scenarios',
            icon: 'fas fa-code-branch text-orange',
            path: '/scenarios',
          }"
        >
        </sidebar-item>
        <!-- <sidebar-item
          :link="{
            name: 'Routing',
            icon: 'fas fa-route text-green',
            path: '/routing',
          }"
        >
        </sidebar-item> -->
      </template>
    </side-bar>
    <div class="main-content">
      <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>

      <div @click="$sidebar.displaySidebar(false)">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

import DashboardNavbar from "../../components/Navbar/DashboardNavbar.vue";

export default {
  components: {
    DashboardNavbar,
  },
  methods: {
    initScrollbar() {
      let isWindows = navigator.platform.startsWith("Win");
      if (isWindows) {
        initScrollbar("sidenav");
      }
    },
  },
  mounted() {
    this.initScrollbar();
  },
};
</script>
<style lang="scss"></style>
