<template>
  <div>
    <div class="container-fluid mt-4">
      <div class="row">
        <div class="col-lg-4">
          <card header-classes="bg-transparent">
            <h1>Scenarios</h1>

            <div class="row mt-5 mb-3">
              <div class="col"><h3>Scenario Creation</h3></div>
              <div class="col">
                <button
                  type="button"
                  class="btn btn-primary w-100"
                  @click="showCreation"
                >
                  Open/Close
                </button>
              </div>
            </div>

            <div v-if="creationVisibility">
              <div class="table-responsive">
                <table class="table">
                  <thead class="thead-light">
                    <tr>
                      <th><strong>Parameter</strong></th>
                      <th><strong>Value</strong></th>
                      <th><strong>Action</strong></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="parameter in scenarioCreationParameter"
                      :key="parameter.id"
                    >
                      <td>{{ parameter.name }}</td>
                      <td>{{ parameter.value }}</td>
                      <td>
                        <button
                          class="btn btn-link btn-edit"
                          @click="
                            scenarioEditVisibility = true;
                            selectedParameterId = parameter.id;
                          "
                        >
                          Edit <i class="far fa-edit"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <!--Classic modal-->
            <modal
              v-model="scenarioEditVisibility"
              :show="scenarioEditVisibility"
            >
              <template v-slot:header>
                <h6 class="modal-title">Edit Parameter</h6>
              </template>
              {{ selectedParameter.name }}
              <input
                type="number"
                class="form-control"
                v-model="selectedParameter.value"
              />

              <template v-slot:footer>
                <base-button
                  type="link"
                  class="ml-auto"
                  @click="scenarioEditVisibility = false"
                  >Close</base-button
                >
              </template>
            </modal>
            <button
              type="button"
              class="btn btn-primary btn-block mt-5 mb-4"
              @click="processScenario"
            >
              <i class="fas fa-play"></i> Process
            </button>
            {{ predictionResult }}
          </card>
        </div>
        <div class="col-lg-8">
          <card>
            <template v-slot:header>
              <!-- Subtitle -->
              <h6 class="surtitle">Map</h6>
              <!-- Title -->
              <h5 class="h3 mb-0">Map</h5>
            </template>
            <!-- Insert Map Here -->
            <SbgGipMap></SbgGipMap>
          </card>

          <!-- <card>
            <template v-slot:header>
              <h6 class="surtitle">Performance</h6>
              <h5 class="h3 mb-0">Detected Cases</h5>
            </template>
            <div class="chart-area">
              <canvas :height="250" :id="barChartID"></canvas>
            </div>
          </card> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ElSelect, ElOption } from "element-plus";

import { barChart } from "@/components/Charts/BarChartScenarios";
import Modal from "@/components/Modal";

import SbgGipMap from "@/components/SbgGipMap";

//Services
import authHeader from "@/services/auth-header";

export default {
  components: {
    Modal,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    SbgGipMap,
  },
  data() {
    return {
      creationVisibility: true,
      sliderValue: 0,
      barChartID: "barChart",
      scenarioEditVisibility: false,
      selectedParameterId: 1,
      predictionResult: "",
      scenarioCreationParameter: [
        {
          id: 1,
          name: "speed_tow_car",
          value: "1",
        },
        {
          id: 2,
          name: "speed_bkw_car",
          value: "1",
        },
        {
          id: 3,
          name: "maxspeed_tow_car",
          value: "1",
        },
        {
          id: 4,
          name: "maxspeed_bkw_car",
          value: "1",
        },
        {
          id: 5,
          name: "lanes_tow",
          value: "1",
        },
        {
          id: 6,
          name: "lanes_bkw",
          value: "1",
        },
        {
          id: 7,
          name: "width_link",
          value: "1",
        },
        {
          id: 8,
          name: "gradient",
          value: "1",
        },
        {
          id: 9,
          name: "street_length",
          value: "1",
        },
        {
          id: 10,
          name: "total_density",
          value: "1",
        },
        {
          id: 11,
          name: "mos_at_intersection",
          value: "1",
        },
        {
          id: 12,
          name: "acc_at_intersection",
          value: "1",
        },
        {
          id: 13,
          name: "time_of_day",
          value: "1",
        },
      ],
    };
  },
  methods: {
    showCreation() {
      this.creationVisibility = !this.creationVisibility;
    },

    processScenario() {
      console.log("process");
      const obj = {
        speed_tow_car: this.scenarioCreationParameter[0].value,
        speed_bkw_car: this.scenarioCreationParameter[1].value,
        maxspeed_tow_car: this.scenarioCreationParameter[2].value,
        maxspeed_bkw_car: this.scenarioCreationParameter[3].value,
        lanes_tow: this.scenarioCreationParameter[4].value,
        lanes_bkw: this.scenarioCreationParameter[5].value,
        width_link: this.scenarioCreationParameter[6].value,
        gradient: this.scenarioCreationParameter[7].value,
        street_length: this.scenarioCreationParameter[8].value,
        total_density: this.scenarioCreationParameter[9].value,
        mos_at_intersection: this.scenarioCreationParameter[10].value,
        acc_at_intersection: this.scenarioCreationParameter[11].value,
        time_of_day: this.scenarioCreationParameter[12].value,
      };
      this.axios
        .post(this.$API_URL + "/scenario", obj, { headers: authHeader() })
        .then(
          (response) => {
            var data = response.data;
            this.predictionResult = data.pred;
            console.log(data);
          },
          (error) => {
            console.log(error);
          }
        );
    },
    copyData() {
      var linkProperties = this.$store.state.linkProperties;
      console.log(linkProperties);
      this.scenarioCreationParameter[0].value = linkProperties.speed_tow_car;
      this.scenarioCreationParameter[1].value = linkProperties.speed_bkw_car;
      this.scenarioCreationParameter[2].value = linkProperties.maxspeed_tow_car;
      this.scenarioCreationParameter[3].value = linkProperties.maxspeed_bkw_car;
      this.scenarioCreationParameter[4].value = linkProperties.lanes_tow;
      this.scenarioCreationParameter[5].value = linkProperties.lanes_bkw;
      this.scenarioCreationParameter[6].value = linkProperties.width_link;
      this.scenarioCreationParameter[7].value = linkProperties.gradient;
      this.scenarioCreationParameter[8].value = linkProperties.street_length;
      var density_str = linkProperties.density.slice(1, -1); // strip [] away
      this.scenarioCreationParameter[9].value = density_str.split(",")[0]; //convert to array and take first value
      var mos_str = linkProperties.mos.slice(1, -1); // strip [] away
      this.scenarioCreationParameter[10].value = mos_str.split(",")[0]; //convert to array and take first value
      var acc_str = linkProperties.acc.slice(1, -1); // strip [] away
      this.scenarioCreationParameter[11].value = acc_str.split(",")[0]; //convert to array and take first value
    },
  },
  computed: {
    selectedParameter: function () {
      var obj = this.scenarioCreationParameter.find(
        (x) => x.id === this.selectedParameterId
      );
      //console.log(obj);
      return obj;
    },
  },
  watch: {
    "$store.state.linkProperties": function () {
      this.copyData();
    },
  },
  mounted() {
    barChart.createChart(this.barChartID);
  },
};
</script>
<style scoped>
td,
th {
  padding: 15px 10px !important;
}

.btn-edit {
  padding: 0 !important;
}
</style>
