<template>
  <div>
    <div class="container-fluid mt-4">
      <div class="row">
        <div class="col-lg-3">
          <card header-classes="bg-transparent" class="sticky-top">
            <base-input
              name=""
              addon-left-icon="ni ni-calendar-grid-58"
              class="mt-5 mb-5"
            >
              <flat-picker
                :config="{ allowInput: true, mode: 'range' }"
                class="form-control datepicker"
                v-model="dateRange"
              >
              </flat-picker>
            </base-input>

            <!-- SELECT ROUTE-->
            <base-input class="mt-5" name="select" label="Select Route">
              <el-select
                placeholder="Select Route"
                v-model="routes.selected"
                filterable
              >
                <el-option
                  v-for="option in routes.options"
                  class="select-danger"
                  :value="option"
                  :label="option"
                  :key="option"
                >
                </el-option>
              </el-select>
            </base-input>

            <button class="btn btn-primary btn-block mt-5" @click="getRoute">
              <i class="fas fa-route"></i> Get Route
            </button>

            <base-input class="mt-5" name="select" label="Select Interval">
              <select
                class="form-control"
                id="exampleFormControlSelect1"
                v-model="selectedInterval"
              >
                <option selected value="1">1min</option>
                <option value="2">2min</option>
                <option value="5">5min</option>
                <option value="10">10min</option>
                <option value="15">15min</option>
                <option value="20">20min</option>
                <option value="30">30min</option>
                <option value="60">1h</option>
              </select>
            </base-input>
          </card>
        </div>
        <!-- RIGHT SIDE -->
        <!-- ================================================== -->
        <div class="col-lg-9">
          <card>
            <template v-slot:header>
              <!-- Subtitle -->
              <h6 class="surtitle">Map</h6>
              <!-- Title -->
              <h5 class="h3 mb-0">Route</h5>
            </template>
            <!-- Insert Map Here -->
            <LineMap></LineMap>
          </card>

          <card>
            <template v-slot:header>
              <!-- Subtitle -->
              <h6 class="surtitle">Performance</h6>
              <!-- Title -->
              <h5 class="h3 mb-0">Detected Warnings</h5>
            </template>
            <div class="chart-area">
              <canvas :height="200" :id="barChartID"></canvas>
            </div>
          </card>

          <card>
            <template v-slot:header>
              <!-- Subtitle -->
              <h6 class="surtitle">Plotly</h6>
              <!-- Title -->
              <h5 class="h3 mb-0">Speed</h5>
            </template>
            <!-- Insert Plotly Here -->
            <PlotlyTest></PlotlyTest>
          </card>

          <!-- <card>
            <template v-slot:header>
              <h6 class="surtitle">Performance</h6>
              <h5 class="h3 mb-0">Detected Cases</h5>
            </template>
            <div class="chart-area">
              <canvas :height="350" :id="lineChartID"></canvas>
            </div>
          </card> -->

          <!-- Table Card -->
          <PaginatedTable />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ElSelect, ElOption } from "element-plus";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import BaseInput from "@/components/Inputs/BaseInput";

import LineMap from "@/components/LineMap";
import PlotlyTest from "@/components/PlotlyTest";
import PaginatedTable from "@/components/PaginatedTable";

import { barChart } from "@/components/Charts/BarChart";
//import { lineChart } from "@/components/Charts/LineChart";

//Services
import authHeader from "@/services/auth-header";
import groupRouteByTimerinterval from "@/services/groupRouteByTimeinterval";

let chart;

export default {
  components: {
    flatPicker,
    BaseInput,
    PlotlyTest,
    LineMap,
    PaginatedTable,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
  },
  data() {
    return {
      dateRange: "2022-04-25 to 2022-05-12",
      barChartID: "barChart",
      lineChartID: "lineChart",
      routes: {
        selected: "",
        options: [],
      },
      route: [],
      selectedInterval: 1,
    };
  },
  methods: {
    updateBarChart(newGroupedRoute) {
      //chart.destroy();
      //chart = barChart.createChart(this.barChartID);
      var x = newGroupedRoute.map((x) => x.time);
      var y = newGroupedRoute.map((x) => x.count);

      if (x == null || y == null) {
        x = [];
        y = [];
      }
      chart.config.data.labels = x;
      chart.config.data.datasets[0].data = y;
      chart.update();
    },
    saveRoutes(routes) {
      if (routes != null) {
        this.routes.options = routes;
      }
    },
    getListOfTripidsFromAPI(dateRange) {
      const obj = { dateRange: dateRange };
      this.axios
        .post(this.$API_URL + "/tripids", obj, { headers: authHeader() })
        .then(
          (response) => {
            this.saveRoutes(response.data);
          },
          (error) => {
            console.log(error);
          }
        );
    },
    getRoute() {
      this.axios
        .get(this.$API_URL + "/route/" + this.routes.selected, {
          headers: authHeader(),
        })
        .then(
          (response) => {
            this.route = response.data;
            this.$store.commit("replaceRoute", response.data);
            groupRouteByTimerinterval.recalculate(this.selectedInterval);
          },
          (error) => {
            console.log(error);
          }
        );
    },
  },
  mounted() {
    chart = barChart.createChart(this.barChartID);
    //lineChart.createChart(this.lineChartID);
    this.getListOfTripidsFromAPI(this.dateRange);
  },
  computed: {
    groupedRouteforBarChart() {
      return this.$store.state.groupedRouteforBarChart;
    },
  },
  watch: {
    dateRange(newValue) {
      this.getListOfTripidsFromAPI(newValue);
    },
    selectedInterval(newValue) {
      groupRouteByTimerinterval.recalculate(newValue);
    },
    groupedRouteforBarChart(newGroupedRoute) {
      this.updateBarChart(newGroupedRoute);
    },
  },
};
</script>
<style scoped></style>
