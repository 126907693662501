import { createStore } from 'vuex';
import { auth } from "./auth.module";

export default createStore({
  state: {
    test: {
      x: [1, 2, 3, 4],
      y: [10, 15, 13, 17]
    },
    route: [],
    groupedRouteforBarChart: [],
    linkProperties: {}
  },
  getters: {
    test: state => {
      return state.test;
    },
    route: state => {
      return state.route;
    },
    groupedRouteforBarChart: state => {
      return state.groupedRouteforBarChart;
    },
    linkProperties: state => {
      return state.linkProperties;
    },

  },
  mutations: {
    addValueTest(state, newValue) {
      state.test.x.push(newValue.x);
      state.test.y.push(newValue.y);
    },
    replaceRoute(state, newRoute) {
      state.route = newRoute;
    },
    replacegroupedRouteforBarChart(state, newGroupedRoute) {
      state.groupedRouteforBarChart = newGroupedRoute;
    },
    replaceLinkProperties(state, newlinkProperties) {
      state.linkProperties = newlinkProperties;
    }
  },

  modules: {
    auth,
  }
})
