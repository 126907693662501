<template>
  <div>
    <div class="container-fluid mt-4">
      <div class="row">
        <div class="col-lg-3">
          <card header-classes="bg-transparent">
            <h1>Hot-Spots</h1>
          </card>
        </div>
        <div class="col-lg-9">
          <card>
            <SbgGipHotspotsMap></SbgGipHotspotsMap>
          </card>

          <!-- <div class="row">
            <div class="col-lg-6">
              -- Timeline card --
              <timeline-card></timeline-card>
            </div>
            <div class="col-lg-6">
              -- Stats cards--
              <stats-cards></stats-cards>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
//import StatsCards from "@/components/StatsCards";
//import TimelineCard from "@/components/TimelineCard";
import SbgGipHotspotsMap from "@/components/SbgGipHotspotsMap";
export default {
  components: {
    //TimelineCard,
    //StatsCards,
    SbgGipHotspotsMap,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
<style scoped></style>
