// Groupt the route by a selected timeinterval and return a list with all the summarized warings with that time interval
import store from '../store'

var groupRouteByTimeinterval = {
  recalculate(timeIntervalMinutes) {
    if (timeIntervalMinutes == null) {
      return;
    }
    timeIntervalMinutes = parseInt(timeIntervalMinutes)
    var route = store.getters.route;

    if (route.length < 1) {
      return;
    }

    //set everything up
    var object = {
      time: "",
      count: 0
    }

    var groupedRoute = [];

    var firstDate = new Date(route[0].datetime);
    var lastDateInMS = firstDate.getTime();
    var timeString = firstDate.getHours() + ":" + (firstDate.getMinutes() < 10 ? '0' : '') + firstDate.getMinutes(); //make sure that 1 gets displaded as 01
    object.time = timeString


    //loop through route
    for (var x of route) {
      //if time difference is bigger than, push object and create new one
      var date = new Date(x.datetime)
      if (date.getTime() >= (lastDateInMS + (timeIntervalMinutes * 60 * 1000))) {
        groupedRoute.push(object);
        lastDateInMS = date.getTime();
        object = {
          time: date.getHours() + ":" + (date.getMinutes() < 10 ? '0' : '') + date.getMinutes(),
          count: 0
        }
      }

      //if riskwarning is true, increase count
      if (parseInt(x.riskbool) == 1) {
        object.count++;
      }
    }

    //push last object to array, otherwise it will get lost
    groupedRoute.push(object);

    store.commit('replacegroupedRouteforBarChart', groupedRoute);
  }
};

export default groupRouteByTimeinterval;