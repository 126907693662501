<template>
  <div>
    <div class="container-fluid mt-4">
      <div class="row">
        <div class="col-lg-3">
          <card header-classes="bg-transparent">
            <h1>Routing</h1>
          </card>
        </div>
        <div class="col-lg-9">
          <card>
            <div>
              <button type="button" class="btn btn-primary" @click="getData">
                Test Connection
              </button>
            </div>
            <div style="margin-top: 40px">
              <span>{{ message }}</span>
            </div>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import authHeader from "@/services/auth-header";

export default {
  components: {},
  data() {
    return {
      message: "",
      test: "",
    };
  },
  methods: {
    getData() {
      console.log(this.$store.getters.test);
      console.log("get data!");
      this.message = "No Connection!";
      this.axios
        .get(this.$API_URL + "/test", { headers: authHeader() })
        .then((response) => (this.message = response.data.message));
    },
  },
  mounted() {},
};
</script>
<style scoped></style>
